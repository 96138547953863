<template>
    <v-dialog v-model="modal" width="800">
        <v-card :loading="isLoading">
            <v-card-title>
                <v-icon class="v-card-close mt-2 me-2" @click="modal = false">mdi-close</v-icon>
                <v-btn
                    v-if="paymentMethods && paymentMethods.length"
                    fab
                    class="me-3"
                    small
                    elevation="0"
                    @click="paymentMethods = []"
                >
                    <v-icon> mdi-arrow-left</v-icon>
                </v-btn>
                <span v-if="!$config.account.paymentSchedulerAutoPaymentMode">
                    {{ `${$t('payment_schedule')}` }}
                </span>
                <span v-else>
                    {{ `${$t('payments')}` }}
                </span>
            </v-card-title>
            <v-card-text>
                <template v-if="!paymentMethods.length">
                    <v-row v-if="isShowTypeSwitcher" class="my-2">
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="invoiceType"
                                :items="invoiceTypes"
                                hide-details
                                solo
                                @change="loadManualInvoices"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="!manualInvoices.length">
                        <v-col cols="12" class="d-flex align-center flex-column justify-center">
                            <div class="text-center text-caption text--secondary">
                                {{ isLoading ? `${$t('loading')}...` : $t('no_data') }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-data-table v-else :items="manualInvoices" :headers="headers" disable-sort>
                        <template v-slot:item.dueDate="{item}">
                            <div class="d-flex align-center">
                                <template v-if="item !== editDueDateItem">
                                    <div v-if="item.dueDate" class="text-no-wrap">
                                        {{ item.dueDate | dateShortFormat }}
                                    </div>
                                    <v-btn
                                        v-if="item.dueDate && isTO1"
                                        right
                                        small
                                        icon
                                        color="primary"
                                        class="ms-1"
                                        @click="editDueDate(item)"
                                    >
                                        <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                                <template v-else>
                                    <date-picker
                                        v-if="item === editDueDateItem"
                                        v-model="newDueDate"
                                        outlined
                                        dense
                                        :min="$dateFns.format(new Date())"
                                        hide-details
                                    />
                                    <v-btn
                                        :disabled="!newDueDate"
                                        right
                                        small
                                        icon
                                        color="success"
                                        class="ms-1"
                                        @click="saveNewDueDate"
                                    >
                                        <v-icon small> mdi-check-circle-outline </v-icon>
                                    </v-btn>
                                </template>
                            </div>
                        </template>
                        <template v-slot:item.paymentStatus="{item}">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-center">
                                    <span :class="getStatusColor(item)">
                                        {{ $t(`paymentStatus.${item.paymentStatus}`) }}
                                    </span>
                                    <v-tooltip
                                        v-if="
                                            item.paymentInfos &&
                                            item.paymentInfos.length &&
                                            (item.paymentInfos.find(i => !!i.remark) ||
                                                item.paymentInfos.find(i => !!i.bankTransferDetails))
                                        "
                                        top
                                    >
                                        <template v-slot:activator="{on}">
                                            <v-btn
                                                icon
                                                small
                                                v-on="item.paymentInfos.find(i => !!i.remark) ? on : null"
                                                @click="
                                                    showedBankTransferInvoiceId === item.invoiceId
                                                        ? (showedBankTransferInvoiceId = null)
                                                        : (showedBankTransferInvoiceId = item.invoiceId)
                                                "
                                            >
                                                <v-icon small>mdi-information-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <div class="d-flex flex-column">
                                            <span v-for="info in item.paymentInfos" :key="info.remark">{{
                                                info.remark
                                            }}</span>
                                        </div>
                                    </v-tooltip>
                                </div>
                                <v-expand-transition>
                                    <div
                                        v-if="showedBankTransferInvoiceId === item.invoiceId"
                                        class="d-flex flex-column"
                                    >
                                        <div v-for="(info, i) in item.paymentInfos" :key="i" class="d-flex flex-column">
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.account') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.bankTransferDetails.account }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.bank') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.bankTransferDetails.bank }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.branch') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.bankTransferDetails.branch }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.iban') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.bankTransferDetails.iban }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.receiving_bank') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.bankTransferDetails.receivingBank }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.reference') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.bankTransferDetails.reference }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.swift_code') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.bankTransferDetails.swiftCode }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {{ $t('bank_transfer.value_date') + ': ' }}
                                                </span>
                                                <span v-if="info.bankTransferDetails.valueDate">
                                                    {{ info.bankTransferDetails.valueDate | dateShortFormat }}
                                                </span>
                                            </div>
                                            <div v-if="info.remark">
                                                <span>
                                                    {{ $t('remark') + ': ' }}
                                                </span>
                                                <span>
                                                    {{ info.remark }}
                                                </span>
                                            </div>
                                            <v-divider v-if="i !== item.paymentInfos.length - 1" class="my-1" />
                                        </div>
                                    </div>
                                </v-expand-transition>
                            </div>
                        </template>
                        <template v-slot:item.payDate="{item}">
                            <div v-if="item.payDate" class="text-no-wrap">
                                {{ item.payDate | dateShortFormat }}
                            </div>
                        </template>
                        <template v-slot:item.amountToPay="{item}">
                            <span v-if="item.originalPrices && item.originalPrices[0]">
                                {{
                                    {
                                        amount: item.originalPrices[0].originalAmount,
                                        currency: item.originalPrices[0].originalAmountCurrency,
                                    } | formatPrice
                                }}
                            </span>
                        </template>
                        <template v-slot:item.pay="{item}">
                            <v-btn
                                v-if="item.paymentStatus === 'BILL'"
                                color="primary"
                                small
                                elevation="0"
                                :disabled="isLoading"
                                @click="initPay(item)"
                            >
                                <v-icon left small> mdi-plus-circle-multiple-outline </v-icon>
                                <span class="caption">
                                    {{ $t('pay') }}
                                </span>
                            </v-btn>
                        </template>
                        <template v-slot:item.cancel="{item}">
                            <v-btn
                                v-if="item.dueDate && item.paymentStatus !== 'CANCELED'"
                                small
                                elevation="0"
                                :disabled="isLoading"
                                color="error"
                                @click="cancelInvoice(item)"
                            >
                                <span class="caption">
                                    {{ $t('cancel') }}
                                </span>
                            </v-btn>
                        </template>
                    </v-data-table>
                </template>
                <order-manual-invoice-payment-form
                    v-else
                    :payment-methods="paymentMethods"
                    :order-id="orderId"
                    :invoice-type="invoiceType"
                    :client-price-currencies="clientPriceCurrencies"
                    @complete-payment="completePayment"
                    @back="paymentMethods = []"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Emit, Prop, PropSync, VModel, Vue, Watch} from 'nuxt-property-decorator'
    import OrderManualInvoicePaymentForm from '@/components/account/OrderManualInvoicePaymentForm'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {authStore} from '@/utils/store-accessor'

    @Component({
        components: {OrderManualInvoicePaymentForm, DatePicker},
    })
    export default class OrderPaymentSchedule extends Vue {
        @VModel() modal
        @PropSync('_isManualInvoicesLoading') isManualInvoicesLoading
        @Prop() objectId
        @Prop() orderId
        @Prop() cancelInvoicesOnLoad
        @Prop() clientPriceCurrencies

        paymentOptionsLoading = false
        newDateSaveLoading = false
        cancelInvoiceLoading = false

        paymentMethods = []
        manualInvoices = []
        issueInfoItems = []

        newDueDate = null
        editDueDateItem = null
        showedBankTransferInvoiceId = null

        invoiceType = 'CUSTOMER'

        silentPay = false

        async initSilentPay(invoiceId) {
            this.reset()
            this.silentPay = true
            await this.$nextTick(() => {})
            await this.initPay({invoiceId})
            this.modal = true
        }

        @Emit()
        completePayment() {}

        @Watch('modal')
        async onModalChange() {
            if (this.modal) {
                if (!this.silentPay) {
                    this.reset()
                    await this.loadManualInvoices()
                }
            } else {
                if (this.cancelInvoicesOnLoad) {
                    await this.cancelInvoiceList(
                        this.manualInvoices.filter(invoice => invoice.paymentStatus === 'BILL')
                    )
                }
                this.silentPay = false
            }
        }

        @Emit()
        async loadManualInvoices() {
            this.manualInvoices = []
            this.nextManualInvoice = null

            this.isManualInvoicesLoading = true

            try {
                let manualInvoices = (
                    await this.$api.manualInvoices.get({orderId: this.objectId, invoiceType: this.invoiceType})
                ).invoices
                    .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
                    .sort(a => (a.paymentStatus === 'CANCELED' ? 1 : -1))
                    .sort(a => (a.paymentStatus === 'BILL' ? -1 : 1))

                if (this.cancelInvoicesOnLoad && !this.silentPay) {
                    await this.cancelInvoiceList(manualInvoices.filter(invoice => invoice.paymentStatus === 'BILL'))
                }

                manualInvoices = manualInvoices.filter(el =>
                    this.$config.account.paymentSchedulerAutoPaymentMode ? el.paymentStatus !== 'CANCELED' : true
                )

                if (this.modal) {
                    this.issueInfoItems = (
                        await this.$api.manualInvoiceIssueInfo.get({
                            orderId: this.objectId,
                            invoiceType: this.invoiceType,
                        })
                    ).items

                    await this.$nextTick(() => {})

                    this.issueInfoItems.forEach(item => {
                        item.prices.forEach(price => {
                            manualInvoices.push({
                                amountToPay: price.amount,
                                amountToPayCurrency: price.currency,
                                originalPrices: [
                                    {
                                        originalAmount: price.amount,
                                        originalAmountCurrency: price.currency,
                                    },
                                ],
                                paymentStatus: 'NO_BILL',
                                supplierName: item.supplierName,
                            })
                        })
                    })
                }

                this.manualInvoices = manualInvoices

                this.nextManualInvoice = this.manualInvoices?.find(m => m.paymentStatus === 'BILL')
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.isManualInvoicesLoading = false
            }

            await this.$nextTick(() => {})

            return this.manualInvoices
        }

        async getPaymentOptions(invoiceId) {
            this.paymentOptionsLoading = true

            try {
                return (
                    await this.$api.manualInvoicePaymentMethods.get({
                        orderId: this.objectId,
                        invoiceType: this.invoiceType,
                        invoiceId: invoiceId,
                    })
                ).invoicePaymentMethods

                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.paymentOptionsLoading = false
            }
        }

        async initPay(item) {
            this.paymentMethods = await this.getPaymentOptions(item.invoiceId)

            return this.paymentMethods
        }

        async saveNewDueDate() {
            this.newDateSaveLoading = true

            try {
                await this.$api.manualInvoices.put(this.editDueDateItem.invoiceId, {
                    dueToPay: `${this.newDueDate}T23:59`,
                })

                this.editDueDateItem.dueDate = `${this.newDueDate} 23:59`
                this.editDueDateItem = null
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.newDateSaveLoading = false
            }
        }

        async cancelInvoiceList(invoices) {
            await Promise.all(
                invoices.map(async invoice => {
                    return await this.cancelInvoice(invoice)
                })
            )
        }

        async cancelInvoice(item) {
            this.cancelInvoiceLoading = true

            try {
                await this.$api.cancelManualInvoice.put(item.invoiceId)

                item.paymentStatus = 'CANCELED'
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.cancelInvoiceLoading = false
            }
        }

        async editDueDate(item) {
            this.newDueDate = this.$dateFns.format(item.dueDate)
            this.editDueDateItem = item
        }

        reset() {
            this.paymentMethods = []
        }

        getStatusColor(item) {
            switch (item.paymentStatus) {
                case 'BILL':
                    return 'error--text'
                case 'PAID':
                    return 'success--text'
                default:
                    return 'grey--text'
            }
        }

        get isLoading() {
            return (
                this.isManualInvoicesLoading ||
                this.paymentOptionsLoading ||
                this.newDateSaveLoading ||
                this.cancelInvoiceLoading
            )
        }

        get headers() {
            const headers = [
                {text: this.$t('invoices.due_date'), value: 'dueDate'},
                {text: this.$t('invoices.amount'), value: 'amountToPay', align: 'center'},
                {text: this.$t('status'), value: 'paymentStatus'},
                {text: this.$t('supplier_name'), value: 'supplierName'},
            ]

            if (this.manualInvoices.find(m => !!m.payDate)) {
                headers.push({text: this.$t('invoices.pay_date'), value: 'payDate'})
            }

            if (this.manualInvoices.find(m => m.paymentStatus === 'BILL')) {
                headers.push({text: this.$t('pay'), value: 'pay', align: 'center'})
            }

            if (this.isTO1 && this.manualInvoices.find(m => m.dueDate && m.paymentStatus !== 'CANCELED')) {
                headers.push({text: this.$t('cancel'), value: 'cancel', align: 'center'})
            }

            return headers
        }

        get invoiceTypes() {
            return [
                {text: this.$t('supplier_payments'), value: 'SUPPLIER'},
                {text: this.$t('client_payments'), value: 'CUSTOMER'},
            ]
        }

        get isShowTypeSwitcher() {
            return this.isTO1 || this.isTO2 || this.isAgency
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isAgency() {
            return authStore.isAgency
        }
    }
</script>
